import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const profileInfoSchema = Yup.object().shape({
    isOfficer1SameRegistrant: Yup.boolean(),
    isOfficer2SameRegistrant: Yup.boolean(),
    isOfficer3SameRegistrant: Yup.boolean(),
    ministryName: Yup.string().required('MinistryNameRequire'),
    purpose: Yup.string().required("PurposeRequire"),
    officer1: Yup.object().when('isOfficer1SameRegistrant', {
        is: false,
        then: Yup.object({
            name: Yup.string().required("NameRequireText"),
            code: Yup.string().required("CivilServantIDRequire"),
            jobTitle: Yup.string().required("JobTitleRequireText"),
            email: Yup.string().required("EmailRequireText").email('EmailNotValidFormat'),
            phone: Yup.string().phone("KH", false, "PhoneNumberInvalidText").required("PhoneNumberRequireText")
        })
    }
    ),
    officer2: Yup.object().when('isOfficer2SameRegistrant', {
        is: false,
        then: Yup.object({
            name: Yup.string().required("NameRequireText"),
            code: Yup.string().required("CivilServantIDRequire"),
            jobTitle: Yup.string().required("JobTitleRequireText"),
            email: Yup.string().required("EmailRequireText").email('EmailNotValidFormat'),
            phone: Yup.string().phone("KH", false, "PhoneNumberInvalidText").required("PhoneNumberRequireText")
        })
    }),
    officer3: Yup.object().when('isOfficer3SameRegistrant', {
        is: false,
        then: Yup.object({
            name: Yup.string().required("NameRequireText"),
            code: Yup.string().required("CivilServantIDRequire"),
            jobTitle: Yup.string().required("JobTitleRequireText"),
            email: Yup.string().required("EmailRequireText").email('EmailNotValidFormat'),
            phone: Yup.string().phone("KH", false, "PhoneNumberInvalidText").required("PhoneNumberRequireText")
        })
    })
});

export const profileInfoValidation = { resolver: yupResolver(profileInfoSchema) };