import i18next from "i18next";
import _ from "lodash";

export const sortCountries = (countriesParam) => {
    let sortProp = "nationality";
    if (i18next.language === 'km') {
        sortProp = "nationalityKh";
    }

    return _.orderBy(countriesParam, [sortProp], ['asc']);
}
