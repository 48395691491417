import { t } from "i18next";
import React from "react";

export function ButtonBack(props) {
  return (
    <button
      hidden={props.hidden}
      onClick={props.onClick}
      size={props.size}
      type={props.type}
      disabled={props.disabled}
      icon={props.icon}
      style={props.style}
      htmlType={props.htmlType}
      className="btn btn-prev"
      title={t("Back")}
      danger={props.danger}
      shape={props.shape}
      loading={props.loading}
    >
      <i className="fas fa-chevron-left"></i>
    </button>
  );
}
