import { AuthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CustomSpinner } from "../../components/share/Spinner";
import { DelegateOfficer1 } from "../../components/government/same_register_info/DelegateOfficer1";
import { DelegateOfficer2 } from "../../components/government/same_register_info/DelegateOfficer2";
import { DelegateOfficer3 } from "../../components/government/same_register_info/DelegateOfficer3";
import { GetQueryParams } from "../../helpers/getUrlParams";
import { GovResubmitProfileStep } from "../../components/government_resubmit/steps/ProfileStep"
import GovernmentProfileService from "../../services/government/ProfileService";
import { InteractionType } from "@azure/msal-browser";
import { ViewCommentModal } from "../../components/share/modals/ViewCommentModal";
import { profileInfoValidation } from "../../models/validations/government/ProfileInfoValidation";
import { useForm } from "react-hook-form";
import { useMsalAccessToken } from "../../hooks/useMsalAccessToken";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "helpers/getLanguage";
import { ButtonForward } from "components/purchase/ButtonForward";

export const GovernmentResubmitProfile = () => {
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, { extraQueryParameters: { ui_locales: getCurrentLanguage() } });

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm(profileInfoValidation);

    const { t } = useTranslation();
    const [visibleCommentDialog, setVisibleCommentDialog] = useState(false);
    const [loading, setLoading] = useState('none')

    //Form Data
    const [domainProfile, setDomainProfile] = useState(null);
    const {
        isOfficer2SameRegistrant,
        isOfficer1SameRegistrant,
        isOfficer3SameRegistrant } = watch();
    let navigate = useNavigate();
    let { domainId } = useParams();
    let queryPrams = GetQueryParams()
    let accessToken = useMsalAccessToken();

    useEffect(() => {
        setDomainProfile({
            domainId: domainId,
            isOfficer1SameRegistrant: true,
            isOfficer2SameRegistrant: true,
            isOfficer3SameRegistrant: true,
            ministryName: "",
            purpose: "",
            officer1: {
                id: 0,
                name: "",
                code: "",
                jobTitle: "",
                email: "",
                phone: ""
            },
            officer2: {
                id: 0,
                name: "",
                code: "",
                jobTitle: "",
                email: "",
                phone: ""
            },
            officer3: {
                id: 0,
                name: "",
                code: "",
                jobTitle: "",
                email: "",
                phone: ""
            },
        });
    }, []);

    useEffect(() => {
        if (accessToken !== undefined) {
            getGovernmentProfile(accessToken);
        }
    }, [accessToken]);

    useEffect(() => {
        reset(domainProfile)
    }, [domainProfile]);

    const getGovernmentProfile = (accessToken) => {
        let profileService = new GovernmentProfileService();
        profileService.getDomainProfile(
            accessToken,
            domainId,
            (data) => {
                let responseData = data.data;
                responseData.domainName = responseData.domainName;
                if (responseData.officer1 === null) {
                    responseData.isOfficer1SameRegistrant = true;
                    responseData.officer1 = {
                        id: 0,
                        name: "",
                        code: "",
                        jobTitle: "",
                        email: "",
                        phone: ""
                    }
                }
                if (responseData.officer2 === null) {
                    responseData.isOfficer2SameRegistrant = true;
                    responseData.officer2 = {
                        id: 0,
                        name: "",
                        code: "",
                        jobTitle: "",
                        email: "",
                        phone: ""
                    }
                }
                if (responseData.officer3 === null) {
                    responseData.isOfficer3SameRegistrant = true;
                    responseData.officer3 = {
                        id: 0,
                        name: "",
                        code: "",
                        jobTitle: "",
                        email: "",
                        phone: ""
                    }
                }
                setDomainProfile(responseData);
                // console.log("Profile", data);
            }
        )
    }

    const onSubmit = data => {
        console.log("data", data);
        data.domainId = Number(domainId);
        data.domainName = domainProfile.domainName;

        setLoading('');
        var profileService = new GovernmentProfileService();
        profileService.saveDomainProfile(accessToken, data, (res) => {
            console.log("save profile response", res);
            navigate(`/government-resubmit/document/${domainId}`);
        }, () => {
            setLoading('none');
        });
    }

    const handleCloseCommentDialog = () => {
        setVisibleCommentDialog(false);
    }

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    const PurposeSection = () => {
        return (
            <div className="card-body pb-0">
                <h4 className="mb-4 d-flex align-items-center justify-content-between">1.1 {t('PurposeoftheWebsite')}
                </h4>
                <section className="personal-info-block">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input {...register("ministryName")} name="ministryName" type="text" className={`form-control`} placeholder="ministryName" />
                                <label className="float-label" htmlFor="MinistryName">{t('MinistryName')}  <span className="text-danger">*</span></label>
                                <div className="text-danger">
                                    {t(errors.ministryName?.message)}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input {...register("purpose", { required: false })} type="text" className={`form-control`} name="purpose" placeholder="purpose " />
                                <label htmlFor="purpose" className="float-label">{t('Purpose')}  <span className="text-danger"> *</span></label>
                                <div className="text-danger">
                                    {t(errors.purpose?.message)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    const Officer1Section = () => {
        return (
            <div className="card-body pb-0">
                <h4 className="mb-4 d-flex align-items-center justify-content-between">1.2. {t('DelegateManagementAndAdministrationToOfficer1')}</h4>
                <div className="custom-control custom-checkbox">
                    <input {...register("isOfficer1SameRegistrant")} id="manager-same-request" className="custom-control-input" type="checkbox" name="isOfficer1SameRegistrant" />
                    <label htmlFor="manager-same-request" className="custom-control-label">{t('SameAsRequesterInfo')}</label>
                </div>
                {
                    !isOfficer1SameRegistrant &&
                    <DelegateOfficer1 register={register} errors={errors} />
                }
                {
                    isOfficer1SameRegistrant &&
                    <div>
                        <small className="text-mute ml-3" >{t('UntickText')}</small>
                    </div>
                }
            </div>
        )
    }

    const Officer2Section = () => {
        return (
            <div className="card-body pb-0">
                <h4 className="mb-4 d-flex align-items-center justify-content-between">1.3. {t('DelegateManagementAndAdministrationToOfficer2')}
                </h4>
                <div className="custom-control custom-checkbox">
                    <input {...register("isOfficer2SameRegistrant")} id="technician-same-request" className="custom-control-input" type="checkbox" name="isOfficer2SameRegistrant" />
                    <label htmlFor="technician-same-request" className="custom-control-label">{t('SameAsRequesterInfo')}</label>
                </div>
                {!isOfficer2SameRegistrant &&
                    <DelegateOfficer2 register={register} errors={errors} />
                }
                {
                    isOfficer2SameRegistrant &&
                    <div>
                        <small className="text-mute ml-3" >{t('UntickText')}</small>
                    </div>
                }
            </div>
        )
    }

    const Officer3Section = () => {
        return (
            <div className="card-body pb-0 mb-4">
                <h4 className="mb-4 d-flex align-items-center justify-content-between">1.4. {t('DelegateManagementAndAdministrationToOfficer3')}
                </h4>
                <div className="custom-control custom-checkbox">
                    <input {...register("isOfficer3SameRegistrant")} id="payer-same-request" className="custom-control-input" type="checkbox" name="isOfficer3SameRegistrant" />
                    <label htmlFor="payer-same-request" className="custom-control-label">{t('SameAsRequesterInfo')}</label>
                </div>
                {
                    !isOfficer3SameRegistrant &&
                    <DelegateOfficer3 register={register} errors={errors} />
                }
                {
                    isOfficer3SameRegistrant &&
                    <div>
                        <small className="text-mute ml-3" >{t('UntickText')}</small>
                    </div>
                }
            </div>
        )
    }

    return (
        <AuthenticatedTemplate>
            <Fragment>
                <div className="purchase-page purchase-wizard body-section">
                    <div className="container-1200 container-1200-max">
                        <div className="heading-section">
                            <h2 className="text-center mb-4">{queryPrams.get("domain")}</h2>
                            <GovResubmitProfileStep />
                        </div>
                        <CustomSpinner display={loading} />
                        <div className="content-section">
                            <div className="feedback p-3 p-lg-4 mb-2">
                                <p className="mb-2">{t('RequireChanges')}</p>
                                <button type="button" className="btn btn-outline-primary mr-2" onClick={() => setVisibleCommentDialog(true)}>{t('ViewComment')}</button>
                            </div>
                        </div>
                        <div className="content-section">
                            <form onSubmit={handleSubmit(onSubmit, onError)} noValidate name="form" className="custom-form needs-validations">
                                <div className="wizard-navigation">
                                    <div className="d-flex btn-groups">
                                        <ButtonForward/>
                                    </div>
                                </div>
                                <div className="card dns-card card-border-top">
                                    {
                                        PurposeSection()
                                    }
                                    {
                                        Officer1Section()
                                    }
                                    {
                                        Officer2Section()
                                    }
                                    {
                                        Officer3Section()
                                    }
                                    <div className="card-footer">
                                        <div className="d-flex justify-content-md-end pt-3 mt-auto">
                                            <button type="submit" className="btn btn-primary btn-block">{t('NextText')}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
            <ViewCommentModal domainId={domainId} visible={visibleCommentDialog} handleCancel={() => handleCloseCommentDialog()} />
        </AuthenticatedTemplate>
    )
}