import './index.css';

import { Fragment, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { AuthenticatedTemplate } from "@azure/msal-react";
import { CUSTOMER_TYPES } from "../../constants/constant";
import { CustomSpinner } from "../../components/share/Spinner";
import { InfoMessageModal } from "../../components/share/modals/InfoMessageModal";
import ServerService from "../../services/purchase/ServerService";
import { serverInfoValidationOption } from "../../models/validations/purchase/ServerInfoValidation";
import { useMsalAccessToken } from "../../hooks/useMsalAccessToken";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

export const UpdateServerInfo = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm(serverInfoValidationOption);
    const { fields, append, replace, remove } = useFieldArray({ name: "nameServerList", control });

    const { t } = useTranslation();

    const [loading, setLoading] = useState('none');
    const [visible, setVisible] = useState(false);

    let navigate = useNavigate();
    let { domainId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    let accessToken = useMsalAccessToken();

    const [domainDetail, setDomainDetail] = useState();

    const isGovernment = Number(searchParams.get("customerType")) === CUSTOMER_TYPES.GOVERNMENT;
    const RouteDomainDetail = () => isGovernment ? `/government-domain-detail/${domainId}` : `/my-domain-detail/${domainId}`;

    useEffect(() => {
        if (accessToken !== undefined) {
            getServerDetail(accessToken);
        }
    }, [accessToken]);

    const getServerDetail = (accessToken) => {
        let serverService = new ServerService();
        serverService.getDomainServerInfo(accessToken, domainId).then(res => {
            setDomainDetail(res.data)
            if (res.status === "Ok" && res.data.nameServerList.length > 0) {
                replace(res.data.nameServerList);
            } else {
                replace([
                    {
                        Id: 1,
                        nameServer: "",
                        ipAddress: ""
                    },
                    {
                        Id: 2,
                        nameServer: "",
                        ipAddress: ""
                    }
                ])
            }
        });
    }

    const onClickAppendField = () => {
        if (fields.length < 4) {
            append({ Id: fields.length + 1, nameServer: "", ipAddress: "" });
        }
    }

    const onSubmit = data => {
        let body = {
            domainId: domainId,
            domainName: domainDetail.domainName,
            nameServerList: data.nameServerList
        }
        let serverService = new ServerService();
        setLoading('');
        serverService.saveServerInfo(accessToken, body).then(res => {
            if (res.status === "Ok") {
                setVisible(true);
                navigate(`/my-domain`);
            }
            setLoading('none');
        });
    }

    return (
        <AuthenticatedTemplate>
            <Fragment>
                {/* <Header isMainPage={false} /> */}
                <div className="purchase-page purchase-wizard body-section">
                    <div className="container-body layout-1 heading-section">
                        <h2 className="text-center mb-4">{domainDetail?.domainName}</h2>
                    </div>
                    <CustomSpinner display={loading} />

                    <div className="content-section container-body layout-2">
                        <form onSubmit={handleSubmit(onSubmit)} name="form" noValidate className="custom-form">
                            <div className="wizard-navigation">
                                <div className="d-flex btn-groups">
                                    <button onClick={() => navigate(RouteDomainDetail())} className="btn btn-prev" title={t("Back")}><i className="fas fa-chevron-left"></i></button>
                                </div>
                            </div>
                            <div className="card dns-card card-border-top">
                                <div className="card-body">
                                    <h4 className="mb-4 d-flex align-items-center align-items-md-start justify-content-between">{t('DomainInformationText')}
                                    </h4>
                                    <div className="body-2-text mb-4">{t('domainInfoText')} <span className="d-block d-sm-inline">{domainDetail?.domainName}</span></div>
                                    <section className="name-server-block">
                                        {
                                            fields.length > 0 && fields.map((field, i) => {
                                                console.log(field)
                                                return (
                                                    <Fragment key={i}>
                                                        <h6 className="my-3">
                                                            {t('NameServerText')} <span className="font-weight-bold">{i + 1} </span>
                                                            <div className="btn-group d-md-none">
                                                                <a className="btn p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <FontAwesomeIcon icon={faCircleInfo} className="text-orange" />
                                                                </a>
                                                                <div className="dropdown-menu drop-mobile dropdown-menu-right p-3 border-primary rounded-lg">
                                                                    <div className="d-flex">
                                                                        <div className="pt-1 mr-2">
                                                                            <FontAwesomeIcon icon={faCircleInfo} className="text-orange" />
                                                                        </div>
                                                                        <div>
                                                                            <div className="arrow-top"></div>
                                                                            <h6 className="card-title">{t('DomainServerInfoTitle')}: </h6>
                                                                            <p>{t('DomainServerInfoText')} <a href="https://www.godaddy.com/whois">https://www.godaddy.com/whois</a>.</p>
                                                                            <ul>
                                                                                <li>
                                                                                    {t('DomainServerInfoText1')}
                                                                                </li>
                                                                                <li>
                                                                                    {t('DomainServerInfoText2')}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btn-group dropright d-none d-md-inline-flex">
                                                                <a className="btn p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <FontAwesomeIcon icon={faCircleInfo} className="text-orange" />
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-right p-3 border-primary rounded-lg">
                                                                    <div className="d-flex">
                                                                        <div className="pt-1 mr-2">
                                                                            <FontAwesomeIcon icon={faCircleInfo} className="text-orange" />
                                                                        </div>
                                                                        <div>
                                                                            <div className="arrow"></div>
                                                                            <h6 className="card-title">{t('DomainServerInfoTitle')}: </h6>
                                                                            <p>{t('DomainServerInfoText')} <a href="https://www.godaddy.com/whois">https://www.godaddy.com/whois</a>.</p>
                                                                            <ul>
                                                                                <li>
                                                                                    {t('DomainServerInfoText1')}
                                                                                </li>
                                                                                <li>
                                                                                    {t('DomainServerInfoText2')}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                i > 1 &&
                                                                <button onClick={() => remove(i)} className="btn ml-n2">
                                                                    <FontAwesomeIcon icon={faTrashCan} className="text-danger" />
                                                                </button>
                                                            }


                                                        </h6>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input defaultValue={field.nameServer} control={control} type="text" {...register(`nameServerList[${i}].nameServer`)} className="form-control" name={`nameServerList[${i}].nameServer`} placeholder="ns1.hostname.com" />
                                                                    <label htmlFor="nameServer1" className="float-label">{t('NameServerLabel')} {i + 1} <span className="text-danger"> *</span></label>
                                                                    <div className="d-flex flex-column">
                                                                        <div>
                                                                            <div className="text-danger">
                                                                                {errors.nameServerList !== undefined && t(errors.nameServerList[i]?.nameServer?.message)}
                                                                            </div>
                                                                        </div>
                                                                        <div><small id="emailHelp" className="form-text text-muted mr-auto">{t('ExampleText')} ns1.hostname.com</small></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group mb-0">
                                                                    <input type="text" defaultValue={field.ipAddress} control={control} {...register(`nameServerList[${i}].ipAddress`)} className="form-control" name={`nameServerList[${i}].ipAddress`} placeholder="117.86.10.5" />
                                                                    <label htmlFor="ipaddressServer1" className="float-label">{t('ipaddressservertext')} {i + 1} <span className="text-danger"> *</span></label>
                                                                    <div className="d-flex flex-column">
                                                                        <div>
                                                                            <div className="text-danger">
                                                                                {errors.nameServerList !== undefined && t(errors.nameServerList[i]?.ipAddress?.message)}
                                                                            </div>
                                                                        </div>
                                                                        <small id="emailHelp" className="form-text text-muted mr-auto">{t('ExampleText')} 117.86.10.5</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                        }

                                    </section>
                                    <div className="d-flex mt-2">
                                        <button disabled={fields.length === 4 ? true : false} type="button" onClick={() => onClickAppendField()} className="btn pl-0">
                                            <i className="fa fa-plus-circle text-orange" aria-hidden="true"></i>
                                            <span className="ml-1 text-primary">{t('AddMoreNameServer')}</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex justify-content-md-end pt-3 mt-auto">
                                        <button className="btn btn-primary btn-block" type="submit">{t('FinishText')}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
            <InfoMessageModal cssClassName="alert-success" visible={visible} infoMessage={t('DataSavedSuccessfully')} />
        </AuthenticatedTemplate>
    )
}