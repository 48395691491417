import axios from "axios";
import { API_ENDPOINT, HEADER_CONFIG } from "../../apiConfig";
import { HTTP_STATUSES } from "../../constants/constant";
const API_NAME ="domain";

export default class DomainService {
    async getDomainById(accessToken, id) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        
        try {            
            const response = await axios
                .get(API_ENDPOINT + `${API_NAME}/${id}`, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            if(error.response.status === HTTP_STATUSES.BAD_REQUEST){
                window.location.href = '/error/bad-request';
            }

            return error;
        }
    };

    async getDomainType() {
        try {
            const response = await axios
                .get(API_ENDPOINT + `${API_NAME}/type`, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    async search(body) {
        try {
            const response = await axios
                .post(API_ENDPOINT + `${API_NAME}/search`,body, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    

    async whoIsSearch(body) {
        try {
            const response = await axios
                .post(API_ENDPOINT + `${API_NAME}/whois`, body, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    async whoIs2Search(accessToken, body) {
        if(accessToken){
            HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        }
        try {
            const response = await axios
                .post(API_ENDPOINT + `${API_NAME}/whois2`, body, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    async getComment(accessToken, id) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        try {            
            const response = await axios
                .get(API_ENDPOINT + `${API_NAME}/comment/${id}`, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            return error;
        }
    };
}