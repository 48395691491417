import { createTheme, ThemeProvider } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from './header/header';

import './layout.scss';
import { useEffect, useState } from 'react';
import { PageNotFound } from '../../../../components/error_pages/PageNotFound';

const Layout = () => {
    const customTheme = createTheme({
        palette: {
            primary: { main: '#014282' },
            secondary: { main: '#ff7c00' }
        },
        breakpoints: {
            values: {
                xxl: 1920
            }
        },
        typography: {
            fontFamily: 'Kantumruy Pro, Poppins, sans-serif'
            // fontSize: '1rem'
        },
        components: {
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        [`& fieldset`]: {
                            borderRadius: '0.75em'
                        },
                        input: {
                            padding: '0.54rem 0.75rem'
                        }
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        [`& fieldset`]: {
                            borderColor: '#014282',
                            borderWidth: '1px !important'
                        }
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: { color: 'red' },
                    root: {
                        fontWeight: 500,
                        fontSize: '0.825em',
                        color: '#000',
                        '&.Mui-focused': {
                            color: '#000'
                        },
                        '&.MuiInputLabel-shrink': {
                            fontSize: '1rem'
                        }
                    }
                }
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        width: '100%'
                    }
                }
            },
            MuiCalendarPicker: {
                styleOverrides: {
                    root: {
                        // fontsiz
                        '& div': {
                            fontSize: '18px'
                        },
                        '& .MuiTypography-root': {
                            fontSize: '16px'
                        },
                        '& .MuiPickersDay-root': {
                            fontSize: '16px'
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: '1em'
                        }
                    }
                }
            }
        }
    });

    const [platform, setPlatform] = useState('')

    useEffect(() => {
        const platform = getDevicePlatform(window);
        setPlatform(platform)
    }, []);

    const getDevicePlatform = (window) => {
        const userAgent = window.navigator.userAgent
        const isAbaMobile = userAgent.indexOf('ABAMobile') !== -1

        return isAbaMobile ? 'abaMobile' : '';
    }

    const isValidPlatform = () => {
        return ['abaMobile'].includes(platform)
    }
    return (
        <ThemeProvider theme={customTheme}>
            <div className="page-container">
                {isValidPlatform() ? (
                    <>
                        <Header />
                        <main className="miniApp">
                            <Outlet />
                        </main>
                    </>
                ) : <PageNotFound isValidPlatform={isValidPlatform()} /> }
            </div>
        </ThemeProvider>
    );
};

export default Layout;
