import './index.css';
import LogoComplete from "../../assets/images/Artboard 1Complete.svg";

import { useAccount, useMsal, useMsalAuthentication } from "@azure/msal-react";

import { Fragment } from "react";
import { InteractionType } from "@azure/msal-browser";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "helpers/getLanguage";

export const PurchaseThank = () => {	
	const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, { extraQueryParameters: { ui_locales: getCurrentLanguage() } });
	const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

	const [searchParams, setSearchParams] = useSearchParams();
	const { t } = useTranslation();

	return (
		<Fragment>
			<div className="page-thank container-1200 mb-5">
				<div className="text-blue">
					<div>
						<div className="head text-center">
							<img src={LogoComplete} width='450' alt="Logo Complete" />
							<h5>{t('ThankPurchased')}</h5>
							<h4 className="py-3 text-orange"><span className="border-bottom pb-4">{searchParams.get("domain")}</span></h4>						
						</div>
						<div className="body text-center mt-3">
							<h6>{t('PurchaseThankText')}</h6>
							<div>{t('PurchaseThankText1')}</div>					
							<a href={`mailTo:${account?.idTokenClaims?.email}`}>{account?.idTokenClaims?.email}</a>
							<Link className="btn btn-primary btn-block" to="/my-domain">{t('ViewMyDomainText')}</Link>
						</div>
					</div>					
				</div>
			</div>
		</Fragment>

	)
}