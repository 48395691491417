const Picture = ({ imgSrc, webpSrc = '', alt = '', draggable = true, className = '' }) => {
    return (
        <picture className={className}>
            {webpSrc && <source srcSet={webpSrc} type="image/webp" />}
            <img src={imgSrc} alt={alt} />
        </picture>
    );
};

export default Picture;
