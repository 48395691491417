import axios from "axios";
import { API_ENDPOINT, HEADER_CONFIG } from "../../apiConfig";
const API_NAME = "payment/process";
export default class PaymentService {
    async getPaymentGateway(accessToken, data) {
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` }
        };

        return axios.post(API_ENDPOINT + API_NAME, data, config);
    };

    async gePaymentOption(accessToken) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        try {
            const response = await axios
                .get(API_ENDPOINT + `payment/option`, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            return error;
        }
    };
    async getPaymentInvoice(invoiceId) {
        try {
            const response = await axios
                .get(API_ENDPOINT + `payment/invoice?invoiceId=${invoiceId}`, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            return error;
        }
    };
}
