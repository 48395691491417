import './index.css';
import LogoSuccess from "../../assets/images/Sucessful verification.svg";

import { AuthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import DomainService from "../../services/domain/DomainService";
import { InteractionType } from "@azure/msal-browser";
import { useMsalAccessToken } from "../../hooks/useMsalAccessToken";
import { useTranslation } from "react-i18next";
import { SuccessStep } from 'components/purchase/steps/SuccessStep';
import { getCurrentLanguage } from "helpers/getLanguage";

export const PurchaseSuccess = () => {
  const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, { extraQueryParameters: { ui_locales: getCurrentLanguage() } });

  const { t, i18n } = useTranslation();
  const [domainDetail, setDomainDetail] = useState();

  let navigate = useNavigate();
  let { domainId } = useParams();
  let accessToken = useMsalAccessToken();

  useEffect(() => {
    if (accessToken !== undefined) {
      getDomainDetail(accessToken);
    }
  }, [accessToken]);

  const getDomainDetail = (accessToken) => {
    let domainService = new DomainService();

    domainService.getDomainById(accessToken, domainId).then(res => {
      setDomainDetail(res.data);
    });
  }

  return (
    <AuthenticatedTemplate>
      <Fragment>
        <div className="purchase-page purchase-success body-section">
          <div className="container-1200 container-1200-max">
            <div className="heading-section">
              <h4 className="text-center mb-4">{domainDetail?.name}</h4>
              <SuccessStep />
            </div>
            <div className="content-section summary-section">
              <div className="card">
                <div className='card-body text-center'>
                  <div className='mb-4'>
                    <img src={LogoSuccess} width='150' alt="Purchase success"></img>
                  </div>
                  <div>
                    <p className="success-text">{t("PurchaseWizardSuccessText1")}<br />
                      {t("PurchaseWizardSuccessText2")}
                      <br /><Link className='text-blue' to='/my-domain'>{t("MyDomainText")}</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </AuthenticatedTemplate>
  )
}