import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { HTTP_STATUSES } from "../../constants/constant";
export const PageNotFound = (props) => {
    const { isValidPlatform = true } = props
  return (
    <React.Fragment>
      <div className="container-1200">
        <div className="row">
          <div className="col-md-12 text-center">
            <Result
              status="404"
              title={t("ErrorTitle" + HTTP_STATUSES.NOT_FOUND)}
							subTitle={t("ErrorSubtitle" + HTTP_STATUSES.NOT_FOUND)}
              extra={
                isValidPlatform ? (
                    <Button type="primary" className="btn btn-primary py-2 px-4">
                        <Link to="/">{t("BackHome")}</Link>
                    </Button>
                ) : ''
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
