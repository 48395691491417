export const CompanyDocumentData = {
    domainId: 0,
    documents: [
        {
            "id": 0,
            "name": "",
            "fileUrl": "",
            "fileType": "",
            "documentTypeId": 20,
        },
        {
            "id": 0,
            "name": "",
            "fileUrl": "",
            "fileType": "",
            "documentTypeId": 21
        },
        {
            "id": 0,
            "name": "",
            "fileUrl": "",
            "fileType": "",
            "documentTypeId": 22
        },
        {
            "id": 0,
            "name": "",
            "fileUrl": "",
            "fileType": "",
            "documentTypeId": 23
        }
    ]
}