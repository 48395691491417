import { faClose, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from 'v2/core/components/antd/modal/custom-modal';
import { useMsalAccessToken } from '../../../hooks/useMsalAccessToken';
import DomainService from '../../../services/domain/DomainService';
import './viewcommentmodal.scss';

export const ViewCommentModal = ({ domainId, visible, handleCancel }) => {
    const { t } = useTranslation();
    let accessToken = useMsalAccessToken();
    const [domainComment, setDomainComment] = useState(undefined);

    useEffect(() => {
        accessToken !== undefined && getDomainComment(accessToken);
    }, [accessToken]);

    const getDomainComment = (accessToken) => {
        var domainService = new DomainService();
        domainService.getComment(accessToken, domainId).then((res) => {
            if (res.statusCode === 'OK') {
                //console.log(res.data);
                setDomainComment(res.data);
            }
        });
    };
    return (
        <CustomModal
            title={t('RequestChanges')}
            visible={visible}
            onCancel={handleCancel}
            footer={''}
            closeIcon={<FontAwesomeIcon icon={faClose} />}
            /* footer={
                <Button onClick={() => handleCancel()}>{t('CloseText')}</Button>
            } */
        >
            {domainComment && (
                <div className="ant-modal-body-container">
                    <div>
                        <textarea
                            cols="60"
                            rows="5"
                            className="form-control"
                            value={domainComment.comment}
                            disabled
                        ></textarea>
                        {domainComment.fileUrl && (
                            <div className="mt-3 file-container">
                                {/* <i className="document-icon fas fa-file-alt fa-2x mr-3"></i> */}
                                <FontAwesomeIcon icon={faFileLines} className="document-icon " />
                                <div className="d-flex flex-column word-break-all">
                                    {/* {domainComment !== undefined && domainComment.fileUrl}<br /> */}
                                    <p>
                                        <a href={domainComment.fileUrl} target="_blank" rel="noopener noreferrer">
                                            {' '}
                                            {t('ViewDownload')}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </CustomModal>
    );
};
