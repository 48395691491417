
import axios from "axios";
import { API_ENDPOINT } from "../../apiConfig";
const API_NAME ="customer";
export default class MyDomainService {
    async getCustomerDomain(accessToken) {
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` }
        };

        return axios.get(API_ENDPOINT+API_NAME+"/domains", config);
    };

}

let domains = [];
export const setDomains = (domainList) => {
    domains = domainList;
}
export const getDomainById = (id) => domains.find(d => d.id === +id);