import React, { Fragment, useEffect, useRef, useState } from 'react';
import DomainService from '../../services/domain/DomainService';
import { HTTP_STATUSES } from '../../constants/constant';
import { SearchDomainContent } from './SearchDomainContent';
import { useSearchParams } from 'react-router-dom';
import SearchComponent from 'v2/core/components/search/search';

export const SearchDomainIndex = () => {
    const [searchParams] = useSearchParams();

    const [result, setResult] = useState(null);
    const [isNotFound, setIsNotFound] = useState(false);

    const domainName = searchParams.get('domain');
    const domainType = searchParams.get('type');
    const inputRef = useRef(null);

    useEffect(() => {
        if (!domainName && !domainName) return;
        let body = {
            domain: domainName,
            domainTypeId: domainType
        };

        searchDomain(body);
    }, [domainName, domainType]);

    const searchDomain = (body) => {
        let domainService = new DomainService();

        domainService
            .search(body)
            .then((res) => {
                setResult(res.data);
                setIsNotFound(false);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === HTTP_STATUSES.NOT_FOUND) {
                        setResult(error.response.data.data);
                        setIsNotFound(true);
                    }
                }
            });
    };

    return (
        <Fragment>
            <div className="domain-search-page body-section">
                <div className="container-1200 container-1200-max">
                    {/* <SearchDomain domainName={domainName}/> */}
                    <SearchComponent withSubmitButton fullWidth />
                    {domainName && (
                        <SearchDomainContent isNotAvailable={isNotFound} query={searchParams} data={result} />
                    )}
                </div>
            </div>
        </Fragment>
    );
};
