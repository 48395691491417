import axios from "axios";
import { HEADER_CONFIG, API_ENDPOINT, FORM_DATA_HEADER_CONFIG } from "../../apiConfig";

const API_NAME = "Government";
export default class GovernmentDocumentService {

    async saveDocument(accessToken, body) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        try {
            const response = await axios
                .post(API_ENDPOINT + `${API_NAME}/document`, body, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    async getDomainDocument(accessToken, domainId) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        try {
            const response = await axios
                .get(API_ENDPOINT + `${API_NAME}/document?domainId=${domainId}`, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    async uploadDocument(accessToken, formData) {
        FORM_DATA_HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        return axios.post(API_ENDPOINT + "file/upload", formData, FORM_DATA_HEADER_CONFIG);
    };
}