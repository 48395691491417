const {
    REACT_APP_API_ENDPOINT,
    REACT_APP_FILE_SIZE_LIMIT
  } = process.env;
  
  export const API_ENDPOINT = REACT_APP_API_ENDPOINT;
  export const FILE_SIZE_LIMIT = Number(REACT_APP_FILE_SIZE_LIMIT);
  
  export const HEADER_CONFIG = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "",
    },
  };
  
  export const FORM_DATA_HEADER_CONFIG = {
    headers: {
      "Content-Type": 'multipart/form-data',
      Authorization: "",
    },
  }  