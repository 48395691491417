import React from 'react';
import { useTranslation } from "react-i18next";
export const CompanyInformationSameInfo = ({ register, errors }) => {
    const { t } = useTranslation();

    //console.log(errors);

    return (
        <div className="mt-4">
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <input {...register("companyInfo.companyName")} type="text" className="form-control" name="companyInfo.companyName" placeholder="OrganizationOrCompanyText" />
                        <label htmlFor="companyInfo.companyName" className="float-label">{t('OrganizationOrCompanyText')} <span className="text-danger"> *</span></label>
                        <div className="text-danger">
                            {t(errors.companyInfo?.companyName?.message)}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input {...register("companyInfo.phone")} type="tel" className="form-control" name="companyInfo.phone" placeholder="PhoneNumberText" />
                        <label htmlFor="companyInfo.Name" className="float-label">{t('PhoneNumberText')} <span className="text-danger"> *</span></label>
                        <div className="text-danger">
                            {t(errors.companyInfo?.phone?.message)}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input {...register("companyInfo.email")} type="email" className="form-control" name="companyInfo.email" placeholder="EmailText" />
                        <label htmlFor="companyInfo.email" className="float-label">{t('EmailText')} <span className="text-danger"> *</span></label>
                        <div className="text-danger">
                            {t(errors.companyInfo?.email?.message)}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group mb-0">
                        <textarea {...register("companyInfo.address")} placeholder="AddressText" className="form-control" cols="30" rows="1" name="companyInfo.address" ></textarea>
                        <label htmlFor="companyInfo.address" className="float-label">{t('AddressText')} <span className="text-danger"> *</span></label>
                        <div className="text-danger">
                            {t(errors.companyInfo?.address?.message)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}