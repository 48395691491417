import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import React, { Fragment, useEffect, useState } from 'react';

import { DomainList } from './DomainList';
import { InteractionType } from '@azure/msal-browser';
import MyDomainService from '../../services/my_domain/MyDomainService';
import UserProfileService from '../../services/user/UserProfileService';
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import SearchComponent from 'v2/core/components/search/search';
import { getCurrentLanguage } from "helpers/getLanguage";

export const MyDomain = () => {
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, { extraQueryParameters: { ui_locales: getCurrentLanguage() } });

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [domains, setDomains] = useState();
    const [userProfile, setUserProfile] = useState(undefined);
    let accessToken = useMsalAccessToken();

    useEffect(() => {
        if (accessToken !== undefined) {
            getUserProfile(accessToken);
            getCustomerDomain(accessToken);

        }
    }, [accessToken]);

    const getCustomerDomain = (accessToken) => {
        var myDomainService = new MyDomainService();

        myDomainService.getCustomerDomain(accessToken).then((res) => {
            console.log("domain", res);
            if (res.data.status === "Ok") {
                setDomains(res.data.data)
                setDomains(res.data.data)

                console.log("domain", res.data);
            }
        })
    }
    const getUserProfile = (accessToken) => {
        let userService = new UserProfileService();
        userService.getProfile(accessToken).then((res) => {
            if (res.status === 200) {
                let userProfile = res.data.data;
                setUserProfile(userProfile);
                if (!userProfile.isProfileCompleted) {
                    navigate("/my-profile");
                }
            }
            console.log("user profile ", res);
        })
    }


    return (
        <AuthenticatedTemplate>
            <Fragment>
                <div className="my-domain-page dashboard body-section">
                    <div className="container-1200 container-1200-max">
                        <div className="mb-3">
                            <SearchComponent placeholder={`SearchYourNextDomainNameText`} withSubmitButton fullWidth />
                        </div>
                        <div>
                            <h5 className="page-description word-break-all">{t('MyDomainDescription')}</h5>
                            <h4 className="table-head-text">{t('MyDomainText')}</h4>
                        </div>
                        {
                            userProfile &&
                            <DomainList customerType={userProfile.customerType} domainList={domains} />
                        }
                    </div>
                </div>
            </Fragment>
        </AuthenticatedTemplate>
    )
}