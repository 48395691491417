import React from 'react';
import { useTranslation } from "react-i18next";
export const ManagerContactSameInfo = ({ register, errors }) => {
    const { t } = useTranslation();

    return (
        <div className="row mt-4">
            <div className="col-md-6">
                <div className="form-group">
                    <input {...register("manager.name")} type="text" className="form-control" name="manager.name" id="managerName" placeholder="NameText" />
                    <label htmlFor="managerName" className="float-label">{t('NameText')} <span className="text-danger"> *</span></label>
                    <div className="text-danger">
                        <div>{t(errors.manager?.name?.message)}</div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <input  {...register("manager.companyName", { required: false })} type="text" className="form-control" name="manager.companyName" placeholder="OrganizationOrCompanyText" />
                    <label htmlFor="managerCompanyName" className="float-label">{t('OrganizationOrCompanyText')} <span className="text-danger"> *</span></label>
                    <div className="text-danger">
                        <div>{t(errors.manager?.companyName?.message)}</div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <input {...register("manager.phone")} type="tel" className="form-control" name="manager.phone" placeholder="PhoneNumberText" />
                    <label htmlFor="managerPhone" className="float-label">{t('PhoneNumberText')} <span className="text-danger"> *</span></label>
                    <div className="text-danger">
                        <div>{t(errors.manager?.phone?.message)}</div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <input {...register("manager.email")} type="email" className="form-control" name="manager.email" id="managerEmail" placeholder="EmailText" />
                    <label htmlFor="managerEmail" className="float-label">{t('EmailText')} <span className="text-danger"> *</span></label>
                    <div className="helper">{t('emailDescription')}</div>
                    <div className="text-danger">
                        {t(errors.manager?.email?.message)}
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="form-group mb-0">
                    <textarea {...register("manager.address")} placeholder="AddressText" className="form-control" cols="30" rows="1" name="manager.address" ></textarea>
                    <label htmlFor="managerAddress" className="float-label">{t('AddressText')} <span className="text-danger"> *</span></label>
                    <div className="text-danger">
                        {t(errors.manager?.address?.message)}
                    </div>
                </div>
            </div>
        </div>
    )
}