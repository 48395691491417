export const PersonalDocumentData = {
  domainId: 0,
  documents: [
    {
      "id": 0,
      "name": "",
      "fileUrl": "",
      "fileType": "",
      "documentTypeId": 19
    }
  ]
}