export const rootDomains = [
  {
    "id": 1,
    "type": ".kh"
  },
  {
    "id": 3,
    "type": ".com.kh"
  },
  {
    "id": 4,
    "type": ".net.kh"
  },
  {
    "id": 5,
    "type": ".org.kh"
  },
  {
    "id": 6,
    "type": ".edu.kh"
  }
];

export const govRootDomains = [
  {
    "id": 7,
    "type": ".gov.kh"
  },
  {
    "id": 8,
    "type": ".mil.kh"
  }
]; 

export const allRootDomains = rootDomains.concat(govRootDomains);