import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import CustomModal from 'v2/core/components/antd/modal/custom-modal';
import Picture from 'v2/core/components/picture/picture';
import imgWarning from '../../../v2/assets/images/warning.png';
import webpWarning from '../../../v2/assets/images/warning.webp';
import './infomessagemodal.scss';

export const InfoMessageModal = ({ infoMessage, cssClassName, visible, handleCancel }) => {
    const { t } = useTranslation();
    return (
        <CustomModal
            title={t('DomainKHText')}
            visible={visible}
            onCancel={handleCancel}
            footer={''}
            closeIcon={<FontAwesomeIcon icon={faClose} />}
        >
            <div className={'ant-modal-body-container info-message'}>
                <span className="ant-modal-body-icon info-message-icon">
                    <Picture imgSrc={imgWarning} webpSrc={webpWarning} />
                </span>
                <p className="ant-modal-body-msg info-message-msg">{infoMessage}</p>
            </div>
        </CustomModal>
    );
};
