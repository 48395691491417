import './index.css';

import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DOMAIN_STATUS } from '../../constants/constant';
import { InteractionType } from '@azure/msal-browser';
import { PaymentForm } from './PaymentForm';
import PaymentService from '../../services/payment/PaymentService';
import PurchaseSummaryService from '../../services/purchase/PurchaseSummaryService';
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import { getCurrentLanguage } from "helpers/getLanguage";

export const PaymentIndex = () => {
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, { extraQueryParameters: { ui_locales: getCurrentLanguage() } });

    const [domainDetail, setDomainDetail] = useState(undefined);
    const [paymentOption, setPaymentOption] = useState([]);

    let { domainId, year, domainName } = useParams();
    let navigate = useNavigate();
    let accessToken = useMsalAccessToken();    
    let domain = {
        id: domainId,
        name: domainName,
    };    

    const years = [1,2,3,4,5];

    useEffect(() => {
        if (years.indexOf(Number(year)) == -1) {
            navigate('/error/bad-request');
        }
    }, []);
    
    const isShowingPaymentInfo = (domainDetail) => (
        domainDetail.statusId === DOMAIN_STATUS.EXPIRED ||
        domainDetail.statusId === DOMAIN_STATUS.PENDING_ACTIVATION ||
        domainDetail.statusId === DOMAIN_STATUS.PENDING_PAYMENT ||
        domainDetail.statusId === DOMAIN_STATUS.READY
    )

    useEffect(() => {
        if (accessToken !== undefined) {
            getPurchaseSummary(accessToken);
            getPaymentOption(accessToken);
        }
    }, [accessToken]);

    const getPurchaseSummary = (accessToken) => {
        let summaryService = new PurchaseSummaryService();
        summaryService.getSummaryDetail(accessToken, domainId, (data) => {
            let detail = data.data.domainDetail;

            if(!isShowingPaymentInfo(detail)){
                navigate('/error/bad-request');
            }

            setDomainDetail(detail);
        })
    }

    const getPaymentOption = (accessToken) => {
        var payment = new PaymentService();
        payment.gePaymentOption(accessToken).then((res) => {
            setPaymentOption(res.data.bankOptions)
        })
    }

    return (
        <AuthenticatedTemplate>
            <Fragment>
                {/* <Header isMainPage={false} /> */}
                <div className="purchase-page payment-page body-section">
                    {/* <Payment domain={domain} year={year} /> */}
                    {
                        domainDetail &&
                        <PaymentForm domainDetail={domainDetail} paymentOption={paymentOption} domain={domain} year={year} />
                    }                    
                </div>
            </Fragment>
        </AuthenticatedTemplate>
    )
}