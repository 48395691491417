import { DOCUMENT_STATUS, DOMAIN_STATUS } from "../constants/constant";

export const getBadgeClasses = (domainStatus) => { 
    switch(domainStatus){
        case DOMAIN_STATUS.EXPIRED:
            return "badge-danger text-danger";
        case DOMAIN_STATUS.DRAW:    
        case DOMAIN_STATUS.PENDING_PAYMENT:
        case DOMAIN_STATUS.PENDING_ACTIVATION:
        case DOMAIN_STATUS.REQUESTED:
            return "badge-warning";
        case DOMAIN_STATUS.PENDING_DEACTIVATION:
        case DOMAIN_STATUS.DEACTIVATED:
        case DOMAIN_STATUS.REJECTED:
        case DOMAIN_STATUS.REMOVED:
            return "badge-danger";
        default: 
            return "badge-success";
    }
}

export const getBulletClasses = (documentStatus) => {
    switch(documentStatus){
        case DOCUMENT_STATUS.PENDING_APPROVAL:
        case DOCUMENT_STATUS.DRAW:
            return "bullet-warning";
        case DOCUMENT_STATUS.DEACTIVATED:     
        case DOCUMENT_STATUS.REMOVED:
        case DOCUMENT_STATUS.REJECTED: 
        case DOCUMENT_STATUS.PENDING_RESUBMIT:
            return "bullet-danger";
        default: 
            return "bullet-success";
    }
}