import { AuthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { Controller, useForm } from "react-hook-form";
import { DOMAIN_TYPES, INSTITUTION_TYPES } from "../../constants/constant";
import { Fragment, useEffect, useState } from "react";
import { instituteProfileInfoValidation, personalProfileInfoValidation } from "../../models/validations/purchase/ProfileInfoValidation";
import { useNavigate, useParams } from "react-router-dom";

import { BillingContactSameInfo } from "../../components/purchase/same_register_info/BillingContact";
import { CompanyInformationSameInfo } from "../../components/purchase/same_register_info/CompanyInfomation";
import { CustomSpinner } from "../../components/share/Spinner";
import DomainService from "../../services/domain/DomainService";
import { InteractionType } from "@azure/msal-browser";
import { LookupService } from "../../services/LookupService";
import { ManagerContactSameInfo } from "../../components/purchase/same_register_info/ManagerContact";
import { ProfileStep } from "../../components/resubmit/steps/ProfileStep"
import PurchaseProfileService from "../../services/purchase/ProfileService";
import { TechnicianContactSameInfo } from "../../components/purchase/same_register_info/TechnicianContact";
import { ViewCommentModal } from "../../components/share/modals/ViewCommentModal";
import _ from "lodash";
import { institutionTypeList } from "../../models/InstitutionTypeList";
import moment from "moment-timezone";
import { rootDomains } from "../../models/RootDomainList";
import { sortCountries } from "../../helpers/lookupHelper";
import { useMsalAccessToken } from "../../hooks/useMsalAccessToken";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "helpers/getLanguage";
import { ButtonForward } from "components/purchase/ButtonForward";

export const RenewProfileIndex = () => {
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, { extraQueryParameters: { ui_locales: getCurrentLanguage() } });

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState('none')
    const [visibleCommentDialog, setVisibleCommentDialog] = useState(false);

    //Form Data
    const [domainProfile, setDomainProfile] = useState(null);

    const [domainDetail, setDomainDetail] = useState(null);

    let navigate = useNavigate();
    let { domainId } = useParams();
    let accessToken = useMsalAccessToken();

    //Lookups
    const [genders, setGenders] = useState([]);
    const [countries, setCountries] = useState([]);
    const lookupServices = LookupService();

    const isDomainKh = () => {
        if (domainDetail) {
            return domainDetail.domainTypeId === _.find(rootDomains, { type: '.kh' }).id
        }
        return false;
    };

    const infoIndex = isDomainKh() ? [1, 2, 3, 4, 5, 6] : [0, 1, 2, 3, 4, 5];

    const { control, register, handleSubmit, watch, reset, formState: { errors } } = useForm(isDomainKh() ? personalProfileInfoValidation : instituteProfileInfoValidation);
    const {
        isTechnicianSameRegistrant,
        isManagerSameRegistrant,
        isBillingSameRegistrant,
        isCompanySameRegistrant,
        isPersonal } = watch();

    useEffect(() => {
        lookupServices.getGender(accessToken).then(res => {
            setGenders(res.data);
        });

        lookupServices.getCountry(accessToken).then(res => {
            let countriesData = sortCountries(res.data);

            setCountries(countriesData);
        });
    }, []);

    //Sort when change language
    useEffect(() => {
        i18n.on('languageChanged', function (lng) {
            if (countries) {
                setCountries(sortCountries(countries));
            }
        })
    }, [countries]);

    useEffect(() => {
        if (accessToken !== undefined) {
            getDomainDetail(accessToken);
            getPurchaseProfile(accessToken);
        }
    }, [accessToken, genders, countries]);

    useEffect(() => {
        reset(domainProfile)
    }, [domainProfile]);

    const getInstitutionType = (isPersonal, domainTypeId) => {
        let institutionType = null;

        if (isPersonal) {
            institutionType = INSTITUTION_TYPES.PERSONAL;
        } else {
            switch (domainTypeId) {
                case DOMAIN_TYPES.COM:
                case DOMAIN_TYPES.NET:
                    institutionType = INSTITUTION_TYPES.COMPANY;
                    break;
                case DOMAIN_TYPES.ORG:
                    institutionType = INSTITUTION_TYPES.ORGANIZATION;
                    break;
                case DOMAIN_TYPES.EDU:
                    institutionType = INSTITUTION_TYPES.EDUCATIONAL_INSTITUTION;
                    break;
                default:
                    institutionType = INSTITUTION_TYPES.PERSONAL;
            }
        }

        return institutionType;
    }

    const getDomainDetail = (accessToken) => {
        let domainService = new DomainService();

        domainService.getDomainById(accessToken, domainId).then(res => {
            setDomainDetail(res.data);

            console.log(res.data);
        });
    }

    const getPurchaseProfile = (accessToken) => {
        setLoading('');
        let profileService = new PurchaseProfileService();
        profileService.getDomainProfile(
            accessToken,
            domainId,
            (data) => {
                if (!data.data.companyInfo) {
                    data.data.companyInfo = {
                        id: 0,
                        companyName: "",
                        address: "",
                        phone: "",
                        email: ""
                    };
                }

                data.data.isPersonal = data.data.isPersonal.toString();
                setDomainProfile(data.data);
                // console.log("Profile", data);
                setLoading('none');
            }
        )
    }

    const onSubmit = data => {
        //TODO: To remove
        //Use to prevent null institutionTypeId on old domain profile for resubmit only
        if (domainDetail) {
            if (domainDetail.domainTypeId !== DOMAIN_TYPES.KH && data.institutionTypeId === null) {
                data.institutionTypeId = getInstitutionType(false, domainDetail.domainTypeId)
            }
        }
        //End to remove

        data.domainId = domainId;
        data.isPersonal = data.isPersonal === "true";
        //Temporary to pass validation
        data.requester.phone = data.requester.phoneNumber;
        data.requester.country = data.requester.countryId;
        data.requester.gender = data.requester.sexId;

        data.requester.dateOfBirth = moment(data.requester.dateOfBirth).utc(true);

        console.log("data", data);

        //setLoading('')
        var profileService = new PurchaseProfileService();
        profileService.saveDomainProfile(accessToken, data, (res) => {
            console.log("save profile response", res);
            navigate(`/resubmit/document/${domainId}`);
        });
    }
    const onChangePurpose = (isPersonal) => {
        console.log("val", isPersonal);
        let institutionTypeId = domainProfile.institutionTypeId;
        if (isPersonal === "true") {
            institutionTypeId = null;
        }

        setDomainProfile({ ...domainProfile, isPersonal, institutionTypeId });
    }

    const onError = (errors, e) => {
        console.log(isPersonal);
        console.log(errors, e);
    }

    const handleCloseCommentDialog = () => {
        setVisibleCommentDialog(false);
    }

    const PurposeSection = () => {
        return (
            <div className="card-body">
                <h4 className="mb-4 d-flex align-items-center justify-content-between">
                    1.{infoIndex[0]}. {t('PurposeoftheWebsite')}
                </h4>
                <div className="mb-2 body-2-text mr-5 mr-md-0">
                    {t('IsThisDomainForCompanyOrPersonalUsage')}
                </div>
                <div className="d-md-flex align-items-baseline mb-2">
                    <div className="custom-control custom-radio custom-control-inline align-items-center my-3">
                        <input id="isPersonal2" onClick={() => onChangePurpose("true")} {...register("isPersonal")} type="radio" value="true" className="form-check-input" name="isPersonal" />
                        <label htmlFor="isPersonal2" className="ml-2 form-check-label">{t('Personal')}</label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline align-items-center my-3">
                        <input id="isPersonal1" onClick={() => onChangePurpose("false")} {...register("isPersonal")} type="radio" value="false" className="form-check-input" name="isPersonal" />
                        <label htmlFor="isPersonal1" className="ml-2 form-check-label">{t('Institutional')}</label>
                    </div>
                    {
                        isPersonal === "false" &&
                        <div className="form-group mb-0">
                            <select {...register("institutionTypeId")} name="institutionTypeId" className="custom-select form-control pr-5">
                                <option className="text-secondary" value=''>{t("SelectInstitutionText")}</option>
                                {
                                    institutionTypeList.map((institution) => {
                                        return <option key={institution.id} value={institution.id}>{i18n.language === 'en' ? institution.text : institution.textKh}</option>
                                    })
                                }
                            </select>
                            <label htmlFor="institutionTypeId" className="float-label">{t('InstitutionType')}  <span className="text-danger">*</span></label>
                            <div className="text-danger">
                                {t(errors.institutionTypeId?.message)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    const RequesterInfoSection = () => {
        return (
            <div className="card-body">
                <h4 className="mb-4 d-flex align-items-center justify-content-between">
                    1.{infoIndex[1]}. {t('RequesterInformation')}
                </h4>
                <section className="personal-info-block">
                    <div className="block-title">{t('PersonalInformation')}</div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <input {...register("requester.nameKh")} name="requester.nameKh" type="text" className={`form-control`} placeholder="requestName" />
                                <label className="float-label" htmlFor="nameKh">{t('NameText')}  <span className="text-danger">*</span></label>
                                <div className="text-danger">
                                    {t(errors.requester?.nameKh?.message)}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <input {...register("requester.nameEn", { required: false })} type="text" className={`form-control`} name="requester.nameEn" placeholder="​ឈ្មោះ​ជា​អក្សរ​ឡាតាំង " />
                                <label htmlFor="nameEn" className="float-label">{t('LatinNameText')}  <span className="text-danger"> *</span></label>
                                <div className="text-danger">
                                    {t(errors.requester?.nameEn?.message)}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <select {...register("requester.sexId")} name="requester.sexId" className="custom-select form-control">
                                    {
                                        genders &&
                                        genders.map((gender) => {
                                            return <option key={gender.id} value={gender.id}>{i18n.language === 'en' ? gender.text : gender.textKh}</option>
                                        })
                                    }
                                </select>
                                <label htmlFor="gender" className="float-label">{t('SexText')}  <span className="text-danger">*</span></label>
                                <div className="text-danger">
                                    {t(errors.requester?.sexId?.message)}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group form-date mb-md-0">
                                <Controller
                                    name="requester.dateOfBirth"
                                    render={({ field }) => (
                                        <input
                                            type="date"
                                            min="1920-01-01" max="2080-12-31" placeholder={t('DateofBirthText')}
                                            className="form-control form-control-lg"
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(moment(e.target.value, "YYYY-MM-DD").format());
                                            }}
                                            value={moment(field.value).format("YYYY-MM-DD")}
                                        />
                                    )}
                                    control={control}
                                />
                                {/* <input {...register("requester.dateOfBirth")} type="date" id="dateOfBirth" placeholder="ថ្ងៃខែឆ្នាំកំណើត" className="form-control form-control-lg" name="requester.dateOfBirth" /> */}
                                <label htmlFor="dateOfBirth" className="float-label">{t('DateofBirthText')} <span className="text-danger">*</span></label>
                                <div className="text-danger">
                                    {t(errors.requester?.dateOfBirth?.message)}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="custom-form form-group mb-md-0">
                                <select {...register("requester.countryId")} name="requester.countryId" className="form-control">
                                    {
                                        countries &&
                                        countries.map((country) => {
                                            return <option key={country.id} value={country.id}>{i18n.language === 'en' ? country.nationality : country.nationalityKh}</option>
                                        })
                                    }
                                </select>
                                <label htmlFor="countryId" className="float-label">{t('NationalityText')}  <span className="text-danger">*</span></label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group mb-0">
                                <input type="text" {...register("requester.idOrPassportNumber")} name="requester.idOrPassportNumber" className="form-control" placeholder="លេខអត្តសញ្ញាណប័ណ្ណ / លិខិតឆ្លងដែន" />
                                <label htmlFor="idOrPassportNumber" className="float-label">{t('IDOrPassportText')} <span className="text-danger"> *</span></label>
                                <div className="text-danger">
                                    {t(errors.requester?.idOrPassportNumber?.message)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    const ProfessionSection = () => {
        return (
            <div className="card-body">
                <section className="my-1">
                    <div className="block-title">{t('Prefession')}</div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group mb-md-0">
                                <input {...register("requester.jobTitle")} name="requester.jobTitle" type="text" className="form-control" placeholder="jobTitle" />
                                <label htmlFor="jobTitle" className="float-label">{t('JobTitleText')} <span className="text-danger"> *</span></label>
                                <div className="text-danger">
                                    {t(errors.requester?.jobTitle?.message)}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-0">
                                <input {...register("requester.companyName")} type="text" name="requester.companyName" className="form-control" placeholder="companyName" />
                                <label htmlFor="companyName" className="float-label">{t('OrganizationOrCompanyText')} <span className="text-danger"> *</span></label>
                                <div className="text-danger">
                                    {t(errors.requester?.companyName?.message)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    const ContactSection = () => {
        return (
            <div className="card-body">
                <section className="my-1">
                    <div className="block-title">{t('ContactInformation')}</div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <input {...register("requester.email")} type="requester.email" className="form-control" placeholder="email" />
                                <label htmlFor="email" className="float-label">{t('EmailText')} <span className="text-danger"> *</span></label>
                                <div className="text-danger">
                                    {t(errors.requester?.email?.message)}
                                    {/* <div>EmailNotValidFormat</div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <input  {...register("requester.phoneNumber")} name="requester.phoneNumber" type="tel" className="form-control" />
                                <label htmlFor="phoneNumber" className="float-label">{t('PhoneNumberText')} <span className="text-danger"> *</span></label>
                                <div className="text-danger">
                                    {t(errors.requester?.phoneNumber?.message)}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <input {...register("requester.fax")} type="text" className="form-control" placeholder="FaxNumberText" />
                                <label htmlFor="fax" className="float-label">{t('FaxNumberText')} </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group mb-0">
                                <textarea {...register("requester.address")} placeholder="address" className="form-control" cols="30" rows="1" name="requester.address" ></textarea>
                                <label htmlFor="address" className="float-label">{t('AddressText')} <span className="text-danger"> *</span></label>
                                <div className="text-danger">
                                    {t(errors.requester?.address?.message)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    const ManagementInfoSection = () => {
        return (
            <div className="card-body">
                <h4 className="mb-4 d-flex align-items-center justify-content-between">
                    1.{infoIndex[2]}. {t('DomainManagerContact')}
                </h4>
                <div className="body-2-text mb-3">
                    {t('domainMangerDescription')}
                </div>
                <div className="custom-control custom-checkbox">
                    <input {...register("isManagerSameRegistrant")} id="manager-same-request" className="custom-control-input" type="checkbox" name="isManagerSameRegistrant" />
                    <label htmlFor="manager-same-request" className="custom-control-label">{t('SameAsRequesterInfo')}</label>
                </div>
                {
                    !isManagerSameRegistrant &&
                    <ManagerContactSameInfo register={register} errors={errors} />
                }
                {
                    isManagerSameRegistrant &&
                    <div>
                        <small className="text-mute ml-3" >{t('UntickText')}</small>
                    </div>
                }
            </div>
        )
    }

    const TechnicianInfoSection = () => {
        return (
            <div className="card-body">
                <h4 className="mb-4 d-flex align-items-center justify-content-between">
                    1.{infoIndex[3]}. {t('DomainTechnicianContact')}
                </h4>
                <div className="body-2-text mb-3">
                    {t('domainTechnicianDescription')}
                </div>
                <div className="custom-control custom-checkbox">
                    <input {...register("isTechnicianSameRegistrant")} id="technician-same-request" className="custom-control-input" type="checkbox" name="isTechnicianSameRegistrant" />
                    <label htmlFor="technician-same-request" className="custom-control-label">{t('SameAsRequesterInfo')}</label>
                </div>
                {!isTechnicianSameRegistrant &&
                    <TechnicianContactSameInfo register={register} errors={errors} />
                }
                {
                    isTechnicianSameRegistrant &&
                    <div>
                        <small className="text-mute ml-3" >{t('UntickText')}</small>
                    </div>
                }
            </div>
        )
    }

    const BillInfoSection = () => {
        return (
            <div className="card-body">
                <h4 className="mb-4 d-flex align-items-center justify-content-between">
                    1.{infoIndex[4]}. {t('DomainPayerContact')}
                </h4>
                <div className="body-2-text mb-3">
                    {t('domainPayerDescription')}
                </div>
                <div className="custom-control custom-checkbox">
                    <input {...register("isBillingSameRegistrant")} id="payer-same-request" className="custom-control-input" type="checkbox" name="isBillingSameRegistrant" />
                    <label htmlFor="payer-same-request" className="custom-control-label">{t('SameAsRequesterInfo')}</label>
                </div>
                {
                    !isBillingSameRegistrant &&
                    <BillingContactSameInfo register={register} errors={errors} />
                }
                {
                    isBillingSameRegistrant &&
                    <div>
                        <small className="text-mute ml-3" >{t('UntickText')}</small>
                    </div>
                }
            </div>
        )
    }

    const CompanyInfoSection = () => {
        return (
            <div className="card-body">
                <h4 className="mb-4 d-flex align-items-center justify-content-between">
                    1.{infoIndex[5]}. {t('CompanyInformationText')}
                </h4>
                <div className="custom-control custom-checkbox">
                    <input {...register("isCompanySameRegistrant")} id="company-same-request" className="custom-control-input" type="checkbox" name="isCompanySameRegistrant" />
                    <label htmlFor="company-same-request" className="custom-control-label">{t('SameAsRequesterInfo')}</label>
                </div>
                {
                    !isCompanySameRegistrant &&
                    <CompanyInformationSameInfo register={register} errors={errors} />
                }
                {
                    isCompanySameRegistrant &&
                    <div>
                        <small className="text-mute ml-3" >{t('UntickText')}</small>
                    </div>
                }
            </div>
        )
    }

    return (
        <AuthenticatedTemplate>
            <Fragment>
                <div className="purchase-page purchase-wizard body-section">
                    <div className="container-1200 container-1200-max">
                        <div className="heading-section">
                            <h4 className="text-center mb-4">{domainDetail?.name}</h4>
                            <ProfileStep />
                        </div>

                        <CustomSpinner display={loading} />
                        <div className="content-section">
                            <div className="feedback p-3 p-lg-4 mb-2">
                                <p className="mb-2">{t('RequireChanges')}</p>
                                <button type="button" className="btn btn-outline-primary mr-2" onClick={() => setVisibleCommentDialog(true)}>{t('ViewComment')}</button>
                            </div>
                        </div>
                        <div className="content-section">
                            <form onSubmit={handleSubmit(onSubmit, onError)} noValidate name="form" className="custom-form needs-validations">
                                <div className="wizard-navigation">
                                    <div className="d-flex btn-groups">
                                        <ButtonForward/>
                                    </div>
                                </div>
                                <div className="card dns-card card-border-top">
                                    {
                                        isDomainKh() &&
                                        PurposeSection()
                                    }
                                    {
                                        RequesterInfoSection()
                                    }
                                    {
                                        ProfessionSection()
                                    }
                                    {
                                        ContactSection()
                                    }
                                    {
                                        ManagementInfoSection()
                                    }
                                    {
                                        TechnicianInfoSection()
                                    }
                                    {
                                        BillInfoSection()
                                    }
                                    {
                                        (isPersonal === "false") &&
                                        CompanyInfoSection()
                                    }
                                    <div className="card-footer">
                                        <div className="d-flex justify-content-md-end pt-3 mt-auto">
                                            <button type="submit" className="btn btn-primary btn-block">{t('NextText')}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ViewCommentModal domainId={domainId} visible={visibleCommentDialog} handleCancel={() => handleCloseCommentDialog()} />
            </Fragment>
        </AuthenticatedTemplate>
    )
}