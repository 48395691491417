import React from 'react';
import { useTranslation } from "react-i18next";
export const DelegateOfficer3 = ({ register, errors }) => {
    const { t } = useTranslation();

    return (
        <div className="row mt-4">
            <div className="col-md-6">
                <div className="form-group">
                    <input {...register("officer3.name")} type="text" className="form-control" name="officer3.name"  placeholder="NameText" />
                    <label htmlFor="name" className="float-label">{t('NameText')} <span className="text-danger"> *</span></label>
                    <div className="text-danger">
                        <div>{t(errors.officer3?.name?.message)}</div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <input  {...register("officer3.jobTitle", { required: false })} type="text" className="form-control" name="officer3.jobTitle" placeholder="OrganizationOrCompanyText" />
                    <label htmlFor="managerCompanyName" className="float-label">{t('JobTitleText')} <span className="text-danger"> *</span></label>
                    <div className="text-danger">
                        <div>{t(errors.officer3?.jobTitle?.message)}</div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group mb-0">
                    <textarea {...register("officer3.code")} placeholder="AddressText" className="form-control" cols="30" rows="1" name="officer3.code" ></textarea>
                    <label htmlFor="managerAddress" className="float-label">{t('CivilServantID')} <span className="text-danger"> *</span></label>
                    <div className="text-danger">
                        {t(errors.officer3?.code?.message)}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <input {...register("officer3.phone")} type="tel" className="form-control" name="officer3.phone" placeholder="PhoneNumberText" />
                    <label htmlFor="managerPhone" className="float-label">{t('PhoneNumberText')} <span className="text-danger"> *</span></label>
                    <div className="text-danger">
                        <div>{t(errors.officer3?.phone?.message)}</div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-group mb-0">
                    <input {...register("officer3.email")} type="email" className="form-control" name="officer3.email" id="managerEmail" placeholder="EmailText" />
                    <label htmlFor="managerEmail" className="float-label">{t('EmailText')} <span className="text-danger"> *</span></label>
                    {/* <div className="helper">{t('emailDescription')}</div> */}
                    <div className="text-danger">
                        {t(errors.officer3?.email?.message)}
                    </div>
                </div>
            </div>           
        </div>
    )
}