import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

import Routers from './routers';

function App({ instance }) {
    useEffect(() => {
        const existingScript = document.getElementById('googletagmanager');
        if(existingScript) return;
        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-Q7SS0NJRY7';
        script.id = 'googletagmanager';
        script.async = true;
        script.onload = () => {
            const dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', 'G-Q7SS0NJRY7');
        }
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const existingScript = document.getElementById('AbaPayway');
        if(existingScript) return;
        let getInterval
        const script = document.createElement('script');
        script.src = 'https://checkout.payway.com.kh/plugins/checkout2-0.js';
        script.id = 'AbaPayway';
        script.async = true;
        script.onload = () => {
            const req = {
                merchantName: 'Domain .kh',
                barTitle: {
                    title: 'Domain .kh',
                    bgColor: '#004282',
                    color: '#ffffff',
                    safeAreaColor: '#ffffff'
                }
            }

            const profile = {
                callback: function (res) {
                    console.log(res)
                }
            }

            const interval = setInterval(() => {
                try {
                    // eslint-disable-next-line no-undef
                    AbaPayway.miniApp(req);
                    // eslint-disable-next-line no-undef
                    AbaPayway.miniAppGetProfile(profile);
                    clearInterval(interval);
                } catch (e) {
                    console.log(e)
                }
            }, 100);
            getInterval = interval
            return () => clearInterval(interval);
        };
        document.body.appendChild(script);
        return () => {
            if (getInterval) clearInterval(getInterval);
            document.body.removeChild(script);
        };
    }, []);

    return (
        <BrowserRouter>
            <MsalProvider instance={instance}>
                <Routers />
            </MsalProvider>
        </BrowserRouter>
    );
}

export default App;
