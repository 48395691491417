export const institutionTypeList = [
	{
		id: 1,
		text: 'Company',
		textKh: 'ក្រុមហ៊ុន'
	},
	{
		id: 2,
		text: 'Organisation',
		textKh: 'អង្គការ​​ សមាគមឬសហជីព'
	},
	{
		id: 3,
		text: 'Educational Institution',
		textKh: 'គ្រឹះស្ថានអប់រំ'
	}
];
