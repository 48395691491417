import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import { DOCUMENT_STATUS } from '../../constants/constant';
import { DocumentStatus } from '../../components/share/DocumentStatus';
import { ViewCommentModal } from '../../components/share/modals/ViewCommentModal';
import { useTranslation } from "react-i18next";
import { NameValue } from 'components/share/content/NameValue';
import { ShowDocument } from 'components/share/content/ShowDocument';

export const GovernmentDomainDetail = ({ purchaseSummary }) => {
    const { domainDetail, governmentDomainProfileRequest, domainPurchaseDocumentRequest, domainPurchaseServerRequest } = purchaseSummary
    const { t, i18n } = useTranslation();

    const [visibleCommentDialog, setVisibleCommentDialog] = useState(false);

    let navigate = useNavigate();

    const getDomainId = () => domainDetail.id

    const ServerInfoItem = ({ nameServer, index }) => (
        <div key={nameServer.id}>
            <NameValue
                name={t("NameServerText") + ' ' + (index + 1)}
                value={nameServer.nameServer}
            />
            <NameValue
                name={t("IPAddress") + ' ' + (index + 1)}
                value={nameServer.ipAddress}
            />
        </div>
    )

    const ServerInfo = ({ nameServerList }) => (
        <div className='card-body server-info'>
            <h4 className='title text-primary'>{t('ServerInfoTitle')}</h4>
            <hr />
            <div className='content'>
                {
                    nameServerList.map((server, index) => (
                        <ServerInfoItem key={index} nameServer={server} index={index} />
                    ))
                }
            </div>
            <Link to={`/update-server-info/${getDomainId()}?customerType=2`} className='btn btn-block btn-outline-primary text-uppercase mt-4'>{t('AddOrEditDomainInfoText')}</Link>
        </div>
    )

    const ProfileAndDocumentTitle = ({ documentStatusId }) => (
        <div className='card-body border-0 pb-0'>
            <h4 className='title text-primary'>{t('profile-and-documenttitle')}</h4>
            <div>
                <DocumentStatus statusId={documentStatusId} />
            </div>
        </div>
    )

    const DocumentInfo = ({ documents }) => (
        <div className='card-body border-0 py-0'>
            <hr className='my-3' />
            <h4 className='font-weight-bold mb-4'>{t('DocumentText')}</h4>
            <div className='mb-3'>
                {documents.map((document, index) => <ShowDocument document={document} key={index} />)}
            </div>
        </div>
    )

    const Contact = ({ title, contact }) => (
        <div className='card-body border-0 py-0'>
            <hr className='my-3' />
            <h4 className='font-weight-bold mb-4'>{title}</h4>
            {contact &&
                <div className='content'>
                    <NameValue name={t('NameText')} value={contact.name} />
                    <NameValue name={t('JobTitleText')} value={contact.jobTitle} />
                    <NameValue name={t('CivilServantID')} value={contact.code} />
                    <NameValue name={t('PhoneNumberText')} value={contact.phone} />
                    <NameValue name={t('EmailText')} value={contact.email} />
                </div>
            }
        </div>
    )

    const DelegateOfficer1 = ({ contact }) => (
        <Contact title={t('DelegateManagementAndAdministrationToOfficer1')} contact={contact} />
    )

    const DelegateOfficer2 = ({ contact }) => (
        <Contact title={t('DelegateManagementAndAdministrationToOfficer2')} contact={contact} />
    )

    const DelegateOfficer3 = ({ contact }) => (
        <Contact title={t('DelegateManagementAndAdministrationToOfficer3')} contact={contact} />
    )

    const ResubmitDocument = () => {
        if (domainDetail.documentStatusId !== DOCUMENT_STATUS.PENDING_RESUBMIT) {
            return <></>;
        }

        return (
            <>
                <div className="feedback p-3 p-lg-4 mb-2">
                    <p className="mb-2">{t('RequireChanges')}</p>
                    <button type="button" className="btn btn-outline-primary mr-2" onClick={() => setVisibleCommentDialog(true)}>{t('ViewComment')}</button>
                    <button type="button" className="btn btn-primary mr-2" onClick={() => navigate(`/government-resubmit/profile/${getDomainId()}`)}>{t('ResubmitYourDocumentText')}</button>
                </div>
                <ViewCommentModal domainId={getDomainId()} visible={visibleCommentDialog} handleCancel={() => handleCloseCommentDialog()} />
            </>
        )
    }

    const handleCloseCommentDialog = () => {
        setVisibleCommentDialog(false);
    }

    return (
        <div className='content-section'>
            {
                ResubmitDocument()
            }
            <div className="custom-form">
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='summary-section'>
                            <div className='card dns-card card-border-top'>
                                <ProfileAndDocumentTitle
                                    documentStatusId={domainDetail.documentStatusId}
                                />
                                <DocumentInfo
                                    documents={domainPurchaseDocumentRequest.documents}
                                />
                                <DelegateOfficer1
                                    contact={governmentDomainProfileRequest.officer1}
                                />
                                <DelegateOfficer2
                                    contact={governmentDomainProfileRequest.officer2}
                                />
                                <DelegateOfficer3
                                    contact={governmentDomainProfileRequest.officer3}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='d-none d-md-block summary-section'>
                            <div className='card dns-card card-border-top'>
                                <ServerInfo
                                    nameServerList={domainPurchaseServerRequest.nameServerList}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}