import './invoice.scss';
import 'moment/locale/km';
import 'moment/locale/en-in';

import React, { useEffect, useState } from 'react';

import PaymentService from '../../services/payment/PaymentService';
import TRCLOGO from '../../assets/images/trc.jpg';
import TactiengArt from '../../assets/images/tactieng-art.png'
import moment from 'moment-timezone';
import { numberWithCommas } from '../../helpers/formatter';
import { useParams } from 'react-router-dom';

export const Invoice = () => {
    let { invoiceId } = useParams();
    const [invoiceItem, setInvoiceItem] = useState();

    useEffect(() => {
        getInvoice(invoiceId);
    }, []);

    const getInvoice = (invoiceId) => {
        const invoiceService = new PaymentService();
        invoiceService.getPaymentInvoice(invoiceId).then((res) => {
            // console.log("invoice", res);
            if (res.statusCode === "OK") {
                setInvoiceItem(res.data);
            }
        })
    }

    const displayDate = (invoiceDate) => {
        let localeDate = moment(invoiceDate);
        localeDate.locale("km");

        return (
            <React.Fragment>
                <span>ថ្ងៃទី</span>{localeDate.format("DD")} <span>ខែ</span>{localeDate.format("MMM")} <span>ឆ្នាំ</span>{localeDate.format("YYYY")}
            </React.Fragment>
        )
    }

    return (
        <body className="invoice-body d-flex flex-column position-relative" style={bodyStyle}>
            <section className="position-relative h-100">
            {
                invoiceItem &&
                <div id="invoice" className="keep-together" style={{ minWidth: "600px", maxWidth: "210mm" }}>
                    <header>
                        <h6 className="text-center font-bold" style={{ lineHeight: 1.8 }}>
                            ព្រះរាជាណាចក្រកម្ពុជា <br />
                            ជាតិ សាសនា ព្រះមហាក្សត្រ
                        </h6>
                        <div className="text-center">
                            <img src={TactiengArt} style={{ width: "80px" }} />
                        </div>
                        <div className='row'>
                            <div className='col-md-5'>
                                <div className="text-center" style={{ maxWidth: '210px' }}>
                                    <div className="logo mb-3">
                                        <img alt='TRC LOGO' src={TRCLOGO} data-holder-rendered="true" style={{ width: "100px" }} />
                                    </div>
                                </div>    
                                <h6 className="title-trc font-bold mb-1">
                                    {invoiceItem.company.legalLocalName}
                                </h6>                            
                                <p className="font-normal">លេខអត្តសញ្ញាណកម្ម អតប <span className="font-en">{invoiceItem.company.taxNumber}</span></p>
                            </div>     
                        </div>        
                    </header>
                    <div className="qr-block row" style={{ marginTop: "-2rem", marginBottom: "-1rem" }}>
                        <div className="col"></div>
                        <div className="col-auto align-self-center">
                            <h5 className="title text-center">
                                <span className="font-bold" style={{ lineHeight: 2 }}>វិ​ក្កយ​បត្រ​</span> <br />
                                <span className="font-en">INVOICE</span>
                            </h5>
                        </div>
                        <div className="col">
                            <div className='row'>
                                <div className="col"></div>
                                <div className='col-auto'>
                                    <div className="embed-responsive embed-responsive-1by1 mx-auto mb-2" style={{ width: "110px", height: "110px" }}>
                                        <img alt="QR Code" className="embed-responsive-item" src={invoiceItem.imageQRCode} />
                                    </div>
                                    <div className="font-normal">    
                                        <label className="mb-0 d-block">លេខ/<span className="font-en">N&#xb0;:</span> <strong className="font-en">DNS{invoiceItem.number.replace("DNS", "")}</strong></label>
                                        <label className="d-block">
                                            {displayDate(invoiceItem.date)}
                                        </label>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div style={{ lineHeight: 2 }} className="font-normal">
                        <label className="mb-0 d-block">ជូន <span className="font-en">\ To</span>: <span className="font-normal">{!invoiceItem.domainDetail.isCompanyPurpose ? invoiceItem.payer.name :  invoiceItem.payer.companyName}</span><br /></label>
                        <label className="mb-0">អាសយដ្ឋាន <span className="font-en">\ Address</span>:</label> <span className="font-normal">{invoiceItem.payer.address}</span> <br />
                        <label className="mb-0">ទូរស័ព្ទ​លេខ <span className="font-en">\ Tel</span>:</label> <span className="font-normal"><strong>{invoiceItem.payer.phone}</strong></span>
                    </div>
                    <main className="mt-3 invoice">
                        <table className="table table-bordered">
                            <thead>
                                <tr className="text-center">
                                    <th width="120px" style={{ maxWidth: "120px" }}><span className="font-bold">លេខកូដ</span> <br /> <span className="font-en">Code</span></th>
                                    <th><span className="font-bold">បរិយាយ</span> <br /> <span className="font-en">Description</span></th>
                                    <th><span className="font-bold">ចំនួនឆ្នាំ</span> <br /> <span className="font-en">Duration</span></th>
                                    <th><span className="font-bold">តម្លៃ​ឯកតា</span> <br /> <span className="font-en">Unit Price</span></th>
                                    <th><span className="font-bold">សរុប (រៀល)</span> <br /><span className="font-en">Amount (KHR)</span></th>
                                </tr>
                            </thead>
                            {
                                invoiceItem.domainFees.length > 0 &&
                                invoiceItem.domainFees.map((item, i) => {
                                    return (
                                        <tr key={i} className="font-en">
                                            <td className="text-center px-3" width="120px" style={{ maxWidth: "120px", wordBreak: "break-all" }}>{item.code}</td>
                                            <td className="font-normal"><div dangerouslySetInnerHTML={{ __html: item.descriptionHtml }} /></td>
                                            <td className="text-center">{item.year}</td>
                                            <td className="text-right">{numberWithCommas(item.unitPrice)}</td>
                                            <td className="text-right">{numberWithCommas(item.amount)}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tfoot>
                                <tr>
                                    <td colSpan="4" className="py-1 font-normal">សរុបទឹកប្រាក់ត្រូវបង់ <span className="font-en">\ Payable Total:</span></td>
                                    <td className="text-right py-1">
                                        <b>
                                            <u style={{ textDecoration: "underline", textDecorationStyle: "double" }} className="font-en">
                                                {numberWithCommas(invoiceItem.totalIncludeTax)}
                                            </u>
                                        </b>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="py-1 font-normal">ជាអក្សរ <span className="font-en">\ In word</span>: {invoiceItem.totalInKhmerTextThousand}រៀលគត់</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="text-justify">
                            <p className="font-normal mb-0">
                                - វិក្កយបត្រនេះផលិតពីប្រព័ន្ធវិក្កយបត្រស្វ័យប្រវត្តិ ដោយមិនតម្រូវចុះហត្ថលេខាឡើយ។
                            </p>
                            <p className="font-en mb-0">
                                - This invoice is produced by an automated billing
                                system without the need of signature.
                            </p>
                        </div>
                    </main>
                </div>
            }                
            </section>
            <div className="invoice-footer row no-gutters mt-auto">
                <div className="col mt-3">
                    <i className="fas fa-mail-bulk mr-1"></i><span className="font-normal">វិថីលេខ១០២ សង្កាត់វត្តភ្នំ ខណ្ឌដូនពេញ រាជធានីភ្នំពេញ</span>
                </div>
                <div className="col mt-3">
                    <div className="row">
                        <div className="col-auto ml-auto">
                            <div>
                                <i className="fas fa-phone-square mr-1"></i><span className="font-en">(855)23 72 23 33</span>
                            </div>
                            <div>
                                <i className="fas fa-fax mr-1"></i><span className="font-en">(855)23 72 32 22</span>
                            </div>
                        </div>
                        <div className="col-auto offset-md-1">
                            <div>
                                <i className="far fa-envelope mr-1"></i><span className="font-en">info@trc.gov.kh</span>
                            </div>
                            <div>
                                <i className="fas fa-globe mr-1"></i><span className="font-en">www.trc.gov.kh</span>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </body>
    )
}

const bodyStyle = {
    pageBreakInside: "avoid",
    fontSize: "12pt",
    marginLeft: "auto",
    marginRight: "auto",
    width: "calc(210mm + 30px)",
    minHeight: "297mm",
    maxHeight: "297mm",
    paddingTop: "30px"
}