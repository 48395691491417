import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { faChevronDown, faClose, faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCurrentLanguage } from 'helpers/getLanguage';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as GbFlag } from '../../../../assets/svg/gb.svg';
import { ReactComponent as KhFlag } from '../../../../assets/svg/kh.svg';
import { ReactComponent as SVGMenuToggle } from '../../../../assets/svg/mobile-menu.svg';
import '../mobile-navigation/mobile-navigation.scss';
import { useMsalAccessToken } from '../../../../../hooks/useMsalAccessToken';

const MiniAppNavigation = ({ onLoginClick, onLogoutClick, onResetPasswordClick, onSwitchLanguage }) => {
    const accessToken = useMsalAccessToken();
    const toggleClicked = (e) => {
        const mobileNavigation = document.querySelector('.mobile-navigation');
        mobileNavigation.classList.toggle('active');
    };

    const removeClasses = () => {
        const mobileNavigation = document.querySelector('.mobile-navigation');
        mobileNavigation.classList.remove('active');
    };

    const dropdownClicked = (e) => {
        const dropdown = e.target.nextSibling;
        const dropdownBody = dropdown.querySelector('.dropdown-body');
        dropdownBody.classList.toggle('active');
    };

    const location = useLocation();
    const [pathname, setPathname] = useState(null);

    useEffect(() => {
        if (!pathname) setPathname(location.pathname);

        if (pathname && location.pathname !== pathname) {
            setPathname(location.pathname);
            if (accessToken) {
                removeClasses();
            }
        }
    }, [location]);

    const renderFlag = () => {
        if (getCurrentLanguage() === 'km') return <GbFlag/>;
        else return <KhFlag/>;
    };

    return (
        <div className="flex-column mobile">
            <button type="button" className="btnFlag" onClick={onSwitchLanguage}>
                {renderFlag()}
            </button>
            <AuthenticatedTemplate>
                <button onClick={toggleClicked} className="menu-toggle">
                    <SVGMenuToggle/>
                </button>
                <div className="mobile-navigation">
                    <button onClick={toggleClicked}>
                        <FontAwesomeIcon icon={faClose}/>
                    </button>
                    <div className="separator-border no-mg-top"></div>
                    <div className="menu">
                        <button onClick={dropdownClicked} className="curp">
                            {t('DomainText')}
                            <FontAwesomeIcon icon={faChevronDown} fontSize={'16px'}></FontAwesomeIcon>
                        </button>
                        <div className="dropdown">
                            <div className="dropdown-body">
                                <NavLink to="/my-cart" className="link">
                                    <span>{t('MyCartText')}</span>
                                </NavLink>
                                <NavLink to="/my-domain" className="link">
                                    <span>{t('MyDomainText')}</span>
                                </NavLink>
                            </div>
                            <div className="dropdown-footer"></div>
                        </div>
                    </div>
                    <div className="separator-border"></div>
                    <div className="menu">
                        <button onClick={dropdownClicked} className="curp">
                            {t('AccountText')}
                            <FontAwesomeIcon icon={faChevronDown} fontSize={'16px'}></FontAwesomeIcon>
                        </button>
                        <div className="dropdown">
                            <div className="dropdown-body">
                                <NavLink to="/my-profile" className="link">
                                    <span>{t('MyProfileText')}</span>
                                </NavLink>
                                <button className="link" onClick={onLogoutClick}>
                                    <span>{t('LogOutText')}</span>
                                </button>
                            </div>
                            <div className="dropdown-footer"></div>
                        </div>
                    </div>
                    <div className="separator-border"></div>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <button className="btnLoginMiniApp" onClick={onLoginClick}>
                    <FontAwesomeIcon icon={faRightToBracket}/>
                </button>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default MiniAppNavigation;
