import { httpApi } from "../../helpers/httpApi";

const API_NAME = "Government";
export default class GovernmentProfileService {

    getDomainProfile(accessToken, domainId, successCallBack) {
        const uri = API_NAME + "/profile?domainId=" + domainId;
        httpApi.get(accessToken, uri, successCallBack);
    };

    saveDomainProfile(accessToken, body, successCallBack, finallyCallBack) {
        const uri = API_NAME + "/profile";
        httpApi.post(accessToken, uri, body, successCallBack, null, finallyCallBack);
    };
}