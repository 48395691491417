import axios from "axios";
import { HEADER_CONFIG, API_ENDPOINT } from "../apiConfig";

class HttpApi {
    get(accessToken, uri, successCallBack, errorCallBack) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`
        axios.get(API_ENDPOINT + uri, HEADER_CONFIG)
            .then((res) => {
                if (successCallBack !== null) {
                    successCallBack(res.data)
                }
            })
            .catch( (error) => {
                if (errorCallBack != null) {
                    errorCallBack(error)
                } else {
                    this.defaultErrorHandler(error)
                }
            })

    }

    post(accessToken, uri, data, successCallBack, errorCallBack, finallyCallBack) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`
        axios.post(API_ENDPOINT + uri, data, HEADER_CONFIG)
            .then((res) => {
                if (successCallBack !== null) {
                    successCallBack(res.data)
                }
            })
            .catch( (error) => {
                if (errorCallBack != null) {
                    errorCallBack(error)
                } else {
                    this.defaultErrorHandler(error)
                }
            }).finally(()=>{
                if (finallyCallBack != null) {
                    finallyCallBack();
                }                
            });

    }

    defaultErrorHandler(error) {
        //todo: handle error
        console.log(error)
    }
}

export const httpApi = new HttpApi()