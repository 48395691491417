import React, { Fragment, useState, useEffect } from 'react';

import { DOMAIN_STATUS } from '../../constants/constant';
import { InfoMessageModal } from '../../components/share/modals/InfoMessageModal';
import PaymentService from '../../services/payment/PaymentService';
import { numberWithCommas } from '../../helpers/formatter';
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const PaymentForm = ({ domainDetail, paymentOption, domain, year }) => {
    const { t } = useTranslation();
    let { domainId } = useParams();
    const [visible, setVisible] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('abapay-mini-app');
    const [paymentOptionSelect, setPaymentOptionSelect] = useState(undefined);
    const [loading, setLoading] = useState(false);

    let accessToken = useMsalAccessToken();

    const paymentService = new PaymentService();
    const [paymentGateway, setPaymentGateway] = useState({
        bankPostApiUrl: '',
        bankPostData: {}
    });

    const [paymentFormRef, setPaymentFormRef] = useState({});
    const vatCharge = (domainFee, documentFee, vat) => {
        return ((domainFee + documentFee) * vat) / 100;
    };

    useEffect(() => {
        let opt = paymentOption.filter((i) => i.bankId === 1);
        setPaymentOptionSelect(opt[0]);
    });

    useEffect(() => {
        console.log('loading>>>>>', loading)
    }, [loading]);

    const pay = async () => {
        setLoading(true)
        if(loading) return;
        const postData = {
            DomainId: domainId,
            PaymentOption: paymentMethod,
            Year: year
        };
        await paymentService
            .getPaymentGateway(accessToken, postData)
            .then(async (res) => {
                //todo: add error handling in case server return 2XX with some error code in data
                setPaymentGateway(res.data);
                // eslint-disable-next-line no-undef
                await AbaPayway.checkout();
                // const { bankPostData } = res.data
                // eslint-disable-next-line no-undef
                // AbaPayway.miniAppCallHandler('doPayment', {
                //     amount: bankPostData?.amount,
                //     account: bankPostData?.tran_id,
                //     currency: bankPostData?.currency
                // })
                //     .then((res) => {
                //         console.log('doPayment>>>>>>>>>>>>', res);
                //     });
            })
            .catch(function (error) {
                //todo: add error handling
                console.log(error);
            });
        console.log('wait for 3 seconds........')
        setTimeout(() => {
            console.log('finished wait........')
            setLoading(false)
        }, 3000);
    };

    const getPaymentGatewayUrl = () => {
        return paymentGateway.bankPostApiUrl;
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const isRenew = () => domainDetail.statusId !== DOMAIN_STATUS.PENDING_PAYMENT;
    const documentFee = () => (isRenew() ? 0 : paymentOptionSelect.applicationFee ?? 0);
    const getFeePerYear = () => (isRenew() ? domainDetail.renewPrice : domainDetail.price);
    const getDomainFee = () => {
        if (isRenew()) return domainDetail.renewPrice * +year;

        let total = domainDetail.price;
        if (+year > 1) {
            total += (+year - 1) * domainDetail.renewPrice;
        }

        return total;
    };

    return (
        <Fragment>
            <div className="content-section payment-section container-1200">
                <form name="form" className="custom-form">
                    <div className="content d-flex">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card payment-card order-summary p-0">
                                    <div className="card-header">
                                        <h4>{t('YourOrderSummaryText')}</h4>
                                    </div>
                                    <div className="card-body text-blue">
                                        <div className="d-flex justify-content-between body-text mb-3">
                                            <div>{t('domain')}</div>
                                            <div>{domainDetail?.name}</div>
                                        </div>
                                        {!isRenew() && (
                                            <>
                                                <div className="d-flex justify-content-between body-text mb-3">
                                                    <div>{t('firstYearFee')}</div>
                                                    <div>KHR {numberWithCommas(domainDetail.price)} </div>
                                                </div>
                                                <div className="d-flex justify-content-between body-text mb-3">
                                                    <div>
                                                        {t('fromTwoYearFee')}{' '}
                                                        {+year > 1 && `(${+year - 1} ${t('Years')})`}
                                                    </div>
                                                    <div>
                                                        KHR{' '}
                                                        {+year > 1
                                                            ? numberWithCommas(domainDetail.renewPrice * (+year - 1))
                                                            : 0}
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between body-text mb-3">
                                                    <div>{t('DocumentFeeText')}</div>
                                                    <div>
                                                        KHR{' '}
                                                        {paymentOptionSelect !== undefined
                                                            ? numberWithCommas(paymentOptionSelect.applicationFee)
                                                            : 0}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {isRenew() && (
                                            <div className="d-flex justify-content-between body-text mb-3">
                                                <div>{t('renewPriceFee') + ` (${+year} ${t('Years')})`}</div>
                                                <div>KHR {numberWithCommas(domainDetail.renewPrice * +year)}</div>
                                            </div>
                                        )}
                                        <div className="d-flex justify-content-between body-text mb-3">
                                            <div>{t('vat-10')} </div>
                                            <div>
                                                KHR{' '}
                                                {paymentOptionSelect !== undefined
                                                    ? numberWithCommas(
                                                          vatCharge(
                                                              getDomainFee(),
                                                              paymentOptionSelect.applicationFee,
                                                              paymentOptionSelect.vat
                                                          )
                                                      )
                                                    : 0}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between body-text mb-3">
                                            <div>{t('BankFee')}</div>
                                            {paymentOptionSelect !== undefined && !paymentOptionSelect.isFeeApply ? (
                                                <div>{t('feeOnBank')}</div>
                                            ) : (
                                                <div>
                                                    KHR{' '}
                                                    {paymentOptionSelect !== undefined
                                                        ? numberWithCommas(paymentOptionSelect.paymentFee)
                                                        : 0}
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-between body-text">
                                            <div>{t('TotalText')}</div>
                                            {paymentOptionSelect !== undefined && !paymentOptionSelect.isFeeApply ? (
                                                <div>{t('feeOnBank')}</div>
                                            ) : (
                                                <div className="text-orange">
                                                    KHR{' '}
                                                    {paymentOptionSelect !== undefined &&
                                                        numberWithCommas(
                                                            vatCharge(
                                                                getDomainFee(),
                                                                paymentOptionSelect.applicationFee,
                                                                paymentOptionSelect.vat
                                                            ) +
                                                                getDomainFee() +
                                                                documentFee() +
                                                                paymentOptionSelect.paymentFee
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="mt-3">
                                            <a
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    pay();
                                                }}
                                                className={`btn btn-primary btn-block ${loading ? 'disabled' : ''}`}
                                            >
                                                {t('NextText')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div id="aba_main_modal" className="aba-modal">
                    <div className="aba-modal-content">
                        <form
                            method="POST"
                            target="aba_webservice"
                            action={getPaymentGatewayUrl()}
                            id="aba_merchant_request"
                            ref={setPaymentFormRef}
                        >
                            {Object.entries(paymentGateway.bankPostData).map(([key, value]) => (
                                <input key={'aba' + key} type="hidden" name={key} value={value} />
                            ))}
                        </form>
                    </div>
                </div>
            </div>
            <InfoMessageModal
                cssClassName="alert-warning"
                handleCancel={() => handleCancel()}
                visible={visible}
                infoMessage={t('please-choose-payment-method')}
            />
        </Fragment>
    );
};
