import React from 'react';
import { useTranslation } from "react-i18next";
export const TechnicianContactSameInfo = ({ register, errors }) => {
    const { t } = useTranslation();

    return (
        <div className="row mt-4">
            <div className="col-md-6">
                <div className="form-group">
                    <input {...register("technician.name")} type="text" className="form-control" name="technician.name" id="managerName" placeholder="NameText" />
                    <label htmlFor="managerName" className="float-label">{t('NameText')} <span className="text-danger"> *</span></label>
                    <div className="text-danger">
                        <div>{t(errors.technician?.name?.message)}</div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <input  {...register("technician.companyName", { required: false })} type="text" className="form-control" name="technician.companyName" placeholder="OrganizationOrCompanyText" />
                    <label htmlFor="managerCompanyName" className="float-label">{t('OrganizationOrCompanyText')} <span className="text-danger"> *</span></label>
                    <div className="text-danger">
                        <div>{t(errors.technician?.companyName?.message)}</div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <input {...register("technician.phone")} type="tel" className="form-control" name="technician.phone" placeholder="PhoneNumberText" />
                    <label htmlFor="managerPhone" className="float-label">{t('PhoneNumberText')} <span className="text-danger"> *</span></label>
                    <div className="text-danger">
                        {t(errors.technician?.phone?.message)}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <input type="email" {...register("technician.email")} className="form-control" name="technician.email" placeholder="EmailText" />
                    <label htmlFor="technician.email" className="float-label">{t('EmailText')} <span className="text-danger"> *</span></label>
                    <div className="helper">{t('emailDescription')}</div>
                    <div className="text-danger">
                        {t(errors.technician?.email?.message)}
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="form-group mb-0">
                    <textarea {...register("technician.address")} placeholder="AddressText" className="form-control" cols="30" rows="1" name="technician.address" ></textarea>
                    <label htmlFor="technician.address" className="float-label">{t('AddressText')} <span className="text-danger"> *</span></label>
                    <div className="text-danger">
                        {t(errors.technician?.address?.message)}
                    </div>
                </div>
            </div>
        </div>
    )
}