import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.compact.min.css';
import "./assets/styles/main.css";
import "./assets/styles/site.css";
import "./assets/styles/antd-overwrite.css";
import "./assets/styles/update-v2.css";
import { PublicClientApplication } from '@azure/msal-browser';
import App from './App';
import { msalConfig } from './authConfig';
import reportWebVitals from './reportWebVitals';
import './translation/i18n';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={''}>
            <App instance={msalInstance} />
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);
reportWebVitals();
