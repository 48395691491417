import React from "react";

export function CustomSpinner(props) {
  const { display, loadingText } = props;

  return <div id="preloader" style={{display:display}}>
            <div id="preloader-status">
                <div className="spinner">
                    <div className="rect1"></div>
                    <div className="rect2"></div>
                    <div className="rect3"></div>
                    <div className="rect4"></div>
                    <div className="rect5"></div>
                </div>
                <div id="preloader-title">{loadingText ? loadingText : "Loading"}</div>
            </div>
          </div>
}
